import { MovingBody } from "./MovingBody";
import { Spritesheet } from "./Drawing/Spritesheet";
import { ColorTrail } from "./ColorTrail";
import { Coordinates } from "./Types";
import { Rect, Shape } from "./Drawing/Shape";

export class Player extends MovingBody {
  private readonly trail: ColorTrail;
  public readonly shapes: Shape[];

  constructor(
    x: number,
    y: number,
    public readonly width: number,
    public readonly height: number,
    protected collisionChecker: (shape: Shape) => boolean,
    trailLength: number = 100 // Maximum length of the trail
  ) {
    super(x, y);

    const spriteSheet = new Image();
    spriteSheet.src = require("./Sprites/Green_SUPERCAR_CLEAN_All_000-sheet.png");

    this.shapes = [
      new Spritesheet(
        x,
        y,
        width,
        height,
        "#FFF", // Default color, not used in Sprite
        spriteSheet,
        48, // Total frames
        7 // Frames per row
      ),
      new Rect(x, y, width, height),
    ];
    this.trail = new ColorTrail(trailLength);
  }

  // private updateSpriteFrame() {
  //   const spritesheet = this.shape as Spritesheet;
  //   const rotationDegrees = ((this.rotation * 180) / Math.PI + 360) % 360;
  //   const degreesPerFrame = 360 / spritesheet.totalFrames;
  //   const frameDegreeOffset = degreesPerFrame / 2;
  //   const frameIndex = Math.floor(
  //     ((360 + rotationDegrees) / 360) * spritesheet.totalFrames
  //   );
  //   spritesheet.setFrame(frameIndex % spritesheet.totalFrames);
  // }

  move(deltaTimeMultiplier: number, direction: { x: number; y: number }): void {
    super.move(deltaTimeMultiplier, direction);
    this.trail.tryAdd(this.shapes[0].center());
    // this.shapes.forEach((shape) => {
    //   this.trail.add(shape.center());
    // });
  }

  draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void {
    this.trail.draw(ctx, scale, offset);
    this.shapes.forEach((shape) => {
      shape.draw(ctx, scale, offset);
    });
  }
}
