// MovingBody.ts

import { Shape } from "./Drawing/Shape";
import { Direction } from "./Types";

export class MovingBody {
  protected speed: number = 10;
  protected turnSpeed: number = 0.07;
  protected _rotation: number = 0; // radians
  protected shapes: Shape[] = [];

  constructor(protected _x: number, protected _y: number) {}

  get x(): number {
    return this._x;
  }

  set x(value: number) {
    this._x = value;
    this.shapes.forEach((shape) => {
      shape.x = value;
    });
  }

  get y(): number {
    return this._y;
  }

  set y(value: number) {
    this._y = value;
    this.shapes.forEach((shape) => {
      shape.y = value;
    });
  }

  get coordinates() {
    return { x: this.x, y: this.y };
  }

  get rotation(): number {
    return this._rotation;
  }

  set rotation(value: number) {
    this._rotation = value;
    this.shapes.forEach((shape) => {
      shape.rotation = value;
    });
  }

  rotate(value: number) {
    this.rotation += value;
  }

  move(deltaTimeMultiplier: number, direction: Direction): void {
    const deltaRotation = deltaTimeMultiplier * this.turnSpeed * direction.x;
    const deltaX =
      deltaTimeMultiplier * this.speed * Math.cos(this._rotation) * direction.y;
    const deltaY =
      deltaTimeMultiplier * this.speed * Math.sin(this._rotation) * direction.y;
    this.rotate(deltaRotation);
    this.x += deltaX;
    this.y += deltaY;
    this.shapes.forEach((shape) => {
      shape.x = this.x;
      shape.y = this.y;
      shape.rotation = this.rotation;
    });
  }

  toString(): string {
    return `[${this.constructor.name} Coords: (${this._x.toFixed(
      2
    )}, ${this._y.toFixed(2)})]`;
  }
}
