// TrackData.ts

type ShapeData = {
  type: string;
  name: string;
  x: number;
  y: number;
  width: number;
  height: number;
  color: string;
};
type TrackData = ShapeData[];
type TrackDataMap = { [key: string]: TrackData };

const testTracks: TrackDataMap = {
  m64x40: [
    // {
    //   type: "rect",
    //   name: "vertical right",
    //   x: 20,
    //   y: -14,
    //   width: 2,
    //   height: 30,
    //   color: "#6c3525",
    // },
    // {
    //   type: "rect",
    //   name: "horizontal bottom",
    //   x: -13,
    //   y: 16,
    //   width: 35,
    //   height: 2,
    //   color: "#6c3525",
    // },
  ],
};

// const tracks: TrackDataMap = {
//   m64x40: [
//     {
//       type: "rect",
//       name: "horizontal top",
//       x: 0,
//       y: 0,
//       width: 64,
//       height: 2,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "vertical right",
//       x: 63,
//       y: 0,
//       width: 2,
//       height: 40,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "vertical right 2",
//       x: 49,
//       y: 14,
//       width: 2,
//       height: 40,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "vertical center",
//       x: 35,
//       y: 1,
//       width: 2,
//       height: 25,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "horizontal bottom center",
//       x: 22,
//       y: 36,
//       width: 28,
//       height: 2,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "vertical left 2",
//       x: 21,
//       y: 14,
//       width: 2,
//       height: 40,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "vertical left",
//       x: 6,
//       y: 1,
//       width: 2,
//       height: 40,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "horizontal bottom left",
//       x: -2,
//       y: 40,
//       width: 10,
//       height: 2,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "horizontal center left",
//       x: -13,
//       y: 25,
//       width: 8,
//       height: 2,
//       color: "#6c3525",
//     },
//     {
//       type: "rect",
//       name: "left obstacle",
//       x: -6,
//       y: 12,
//       width: 5,
//       height: 2,
//       color: "#6c3525",
//     },
//   ],
// };

export default testTracks;
