// Game.ts

import { Track } from "./Track";
import { Player } from "./Player";
import { Shape } from "./Drawing/Shape";
import {
  Coordinates,
  Dimensions,
  Direction,
  KeyDirections,
  directionCoordinates,
} from "./Types";

const TARGET_FPS = 60; // TODO 30
const TARGET_FRAME_MS = 1000 / TARGET_FPS; // 1000 / 60 = 16.66666
export const gameDimensions: Dimensions = { width: 900, height: 680 };
let keyDirections: KeyDirections;
let direction: Direction;
let maze: Track;
let player: Player;

const playerCollisionChecker = (shape: Shape): boolean =>
  !shapeInGameBounds(shape) || maze.checkCollisions(shape);

export const create = () => {
  const { width, height } = gameDimensions;
  maze = new Track(width, height);
  player = new Player(100, 100, 15, 15, playerCollisionChecker);
  // player = new Player(200, 100, 35, "#000", playerCollisionChecker);
};

export const destroy = () => {};

export const update = (deltaTime: number, newKeyDirections: KeyDirections) => {
  // newKeyDirections = { ...newKeyDirections, up: true, right: true };  // DEBUG
  if (keyDirections !== newKeyDirections) {
    keyDirections = newKeyDirections;
    direction = directionCoordinates(keyDirections);
  }
  const deltaTimeMultiplier = deltaTime / TARGET_FRAME_MS;
  const move = {
    x: direction.x,
    y: direction.y,
  };
  player.move(deltaTimeMultiplier, move);
};

export const render = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  scale: number,
  offset: Coordinates
) => {
  // console.log(`Canvas dimensions: (${canvas.width}, ${canvas.height})`);
  // ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawBackground(ctx, canvas, scale, offset);
  maze.draw(ctx, scale, offset);
  player.draw(ctx, scale, offset);

  // Call renderScore with default position
  renderScore(ctx, canvas, { x: canvas.width / 2, y: canvas.height - 30 });

  // Render directional input keys
  renderInputs(ctx, canvas, keyDirections);
};

const drawBackground = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  scale: number,
  offset: Coordinates
) => {
  ctx.fillStyle = "black";
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "grey";
  ctx.fillRect(
    offset.x,
    offset.y,
    scale * gameDimensions.width,
    scale * gameDimensions.height
  );
  // const outlineWidth = 2;
  // ctx.fillStyle = "blue";
  // ctx.fillRect(0, 0, canvas.width, canvas.height);
};

export const shapeInGameBounds = (shape: Shape) => {
  const inBounds =
    shape.left().x >= 0 &&
    shape.top().y >= 0 &&
    shape.right().x <= gameDimensions.width &&
    shape.bottom().y <= gameDimensions.height;
  return inBounds;
};

export const pointInGameBounds = ({ x, y }: Coordinates) => {
  const inBounds =
    x >= 0 && y >= 0 && x <= gameDimensions.width && y <= gameDimensions.height;
  return inBounds;
};

/**
 * Renders the score on the canvas.
 * @param ctx - Canvas rendering context
 * @param canvas - Canvas element
 * @param position - Coordinates for the score display position
 */
const renderScore = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  position: Coordinates = { x: canvas.width / 2, y: canvas.height - 30 }
) => {
  ctx.font = "40px Arial";
  ctx.fillStyle = "green";
  ctx.textAlign = "center";
  ctx.fillText("0", position.x, position.y);
};

/**
 * Renders the directional input keys (Up, Left, Down, Right) on the canvas.
 * @param ctx - Canvas rendering context
 * @param canvas - Canvas element
 * @param keyDirections - Object containing the current state of directional keys
 */
export const renderInputs = (
  ctx: CanvasRenderingContext2D,
  canvas: HTMLCanvasElement,
  keyDirections: { up: boolean; down: boolean; left: boolean; right: boolean }
) => {
  const keySize = 50; // Size of each key box
  const spacing = 10; // Spacing between keys
  const centerX = canvas.width / 2;
  const centerY = canvas.height - 100; // Position near the bottom of the canvas

  // Define the positions of the keys
  const keys = [
    {
      label: "Up",
      x: centerX,
      y: centerY - keySize - spacing,
      active: keyDirections.up,
    },
    {
      label: "Left",
      x: centerX - keySize - spacing,
      y: centerY,
      active: keyDirections.left,
    },
    { label: "Down", x: centerX, y: centerY, active: keyDirections.down },
    {
      label: "Right",
      x: centerX + keySize + spacing,
      y: centerY,
      active: keyDirections.right,
    },
  ];
  ctx.font = "16px Arial";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";
  keys.forEach(({ label, x, y, active }) => {
    // Draw the key box
    ctx.fillStyle = active ? "yellow" : "white";
    ctx.fillRect(x - keySize / 2, y - keySize / 2, keySize, keySize);
    // Draw the border
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.strokeRect(x - keySize / 2, y - keySize / 2, keySize, keySize);
    // Draw the label
    ctx.fillStyle = "black";
    ctx.fillText(label, x, y);
  });
};
