// Trail.ts

import { Coordinates } from "./Types";

/**
 * A class to manage a fixed-size queue of coordinates and draw them to the canvas.
 */
export class Trail {
  protected trail: Coordinates[] = [];
  protected debounceMs = 100;
  protected lastUpdated: number;
  protected allowRepeats = false;
  protected lastAdded: Coordinates | null = null;

  /**
   * Constructs a new Trail instance.
   * @param maxLength - The maximum number of coordinates to store in the trail.
   */
  constructor(protected maxLength: number = 100) {
    this.lastUpdated = performance.now();
  }

  /**
   * Adds a new coordinate to the trail.
   * @param coord - The coordinate to add.
   */
  add(coord: Coordinates): Coordinates | undefined {
    this.trail.push(coord);
    this.lastAdded = coord;
    if (this.trail.length > this.maxLength) {
      return this.trail.shift();
    }
  }

  /**
   * Calls this.add() if not debounced, the coordinate is not a repeat, etc.
   * @param coord - The coordinate to add.
   */
  tryAdd(
    coord: Coordinates,
    updateTime = performance.now()
  ): boolean | Coordinates {
    const nextAllowedUpdateTime = this.lastUpdated + this.debounceMs;
    if (updateTime < nextAllowedUpdateTime) {
      return false;
    }
    if (
      !this.allowRepeats &&
      coord.x === this.lastAdded?.x &&
      coord.y === this.lastAdded?.y
    ) {
      return false;
    }
    return this.add(coord) || true;
  }

  /**
   * Draws the trail on the canvas.
   * @param ctx - The canvas rendering context.
   * @param scale - The scale factor for the coordinates.
   * @param offset - The offset to apply to each coordinate.
   */
  draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void {
    if (this.trail.length < 2) return; // Not enough points to draw a trail

    ctx.beginPath();
    ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
    ctx.lineWidth = 2;

    // Move to the first point in the trail
    const firstPoint = this.trail[0];
    ctx.moveTo(
      offset.x + scale * firstPoint.x,
      offset.y + scale * firstPoint.y
    );

    // Draw lines connecting all the points in the trail
    for (const point of this.trail) {
      ctx.lineTo(offset.x + scale * point.x, offset.y + scale * point.y);
    }

    ctx.stroke();
  }
}
