// ColorTrail.ts

import { Trail } from "./Trail";
import { Coordinates } from "./Types";
import { generateHexColor } from "../Utilities/Colors";

export interface ColorCoordinates extends Coordinates {
  color: string;
}

/**
 * A class to manage a fixed-size queue of coordinates with associated colors.
 * Each point is drawn with its assigned color.
 */
export class ColorTrail extends Trail {
  protected trail: ColorCoordinates[] = [];
  private colorIndex: number = 0;
  private colorIndexIncrement: number = 3.5;

  /**
   * Adds a new coordinate to the trail with an associated color.
   * @param coord - The coordinate to add.
   */
  add(coord: Coordinates): ColorCoordinates | undefined {
    const color = generateHexColor(this.colorIndex);
    this.trail.push({ ...coord, color });
    this.colorIndex += this.colorIndexIncrement;
    if (this.trail.length > this.maxLength) {
      return this.trail.shift();
    }
  }

  /**
   * Draws the trail on the canvas.
   * Each line segment is drawn with the color of its starting point.
   * @param ctx - The canvas rendering context.
   * @param scale - The scale factor for the coordinates.
   * @param offset - The offset to apply to each coordinate.
   */
  draw(
    ctx: CanvasRenderingContext2D,
    scale: number,
    offset: Coordinates
  ): void {
    // if (this.trail.length < 2) return; // Not enough points to draw a trail

    ctx.lineWidth = 2;

    for (let i = 1; i < this.trail.length; i++) {
      const start = this.trail[i - 1];
      const end = this.trail[i];

      ctx.strokeStyle = start.color; // Use the color associated with the starting point

      ctx.beginPath();
      ctx.moveTo(offset.x + scale * start.x, offset.y + scale * start.y);
      ctx.lineTo(offset.x + scale * end.x, offset.y + scale * end.y);
      ctx.stroke();
    }
  }
}
